import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ApiGetResponose } from 'common/types/companies';

import { API_PATHS } from './path';

export const useCompanies = () => {
  return useQuery<ApiGetResponose>({
    queryKey: [API_PATHS.companies],
    queryFn: () => axios.get(API_PATHS.companies).then((res) => res.data),
  });
};
