var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Add, DeleteOutline, DownloadOutlined, LaunchOutlined, Start, SyncOutlined, } from '@mui/icons-material';
import { Alert, Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { numberOrDefault } from 'common/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import CommonFormatter from 'common/Formatter';
import { DocumentTypeLabels } from 'common/constants/documents';
import UploadOverrideFile from '@/components/documents/DocumentsView/DocumentOverrideFile';
import ExtractMethod from '@/components/documents/DocumentsView/ExtractMethod';
import UpdateProcessData from '@/components/documents/DocumentsView/UpdateProcessData';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useSetOriginFile } from '@/store/excelStore';
import { BasicDialog, FileDialogPreview } from '@/common';
import { PDF_HTML_IMG_TYPES } from '@/common/preview/model';
import { DocumentPreviewKeys } from '@/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { useAccountStore } from '@/store';
import usePreviewParams from '@/contexts/usePreviewParams';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import useSnackbar from '@/contexts/useSnackbar';
const DocumentsView = ({ variant = '' }) => {
    var _a;
    const { selectedAccount } = useAccountStore();
    const queryClient = useQueryClient();
    const enableAccountId = ['W4kSrayZvmh26pGfYVrGE', 'fFF86XAy2Cu97xxra8lgA'];
    const isRiskTag = enableAccountId.includes((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) || '') ||
        ((_a = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('risktag'));
    const [sync, setSync] = useState({ documentId: '', show: false, count: 0 });
    const [open, setOpen] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showExtract, setShowExtract] = useState(false);
    const [rowData, setRowData] = useState({});
    const { setLoadingConfig } = useContext(LoadingContext);
    const { downloadFile } = useDownloadStorageFile();
    const setUploadedFile = useSetOriginFile();
    const { showSnackbar } = useSnackbar();
    const documentsDelete = API.getMutation('documents', 'DELETE');
    const syncStatement = API.getMutation('data_processing/sync/benefit-point/statements', 'POST');
    const { showPreview, setShowPreview, previewId, setPreviewPath } = usePreviewParams();
    const filePathFormatter = (filename, row) => filename ? (_jsx(Tooltip, { title: filename, arrow: true, enterNextDelay: 1000, children: _jsx(Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setPreviewPath(row.str_id, DocumentPreviewKeys.ORIGINAL);
                setShowPreview(true);
            }), children: _jsx("span", { style: {
                    maxWidth: 240,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }, children: filename }) }) })) : ('--');
    const deleteData = (row) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Deleting...',
            });
            yield documentsDelete.mutateAsync({ id: row.id });
            queryClient.invalidateQueries();
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(`Deleted ${row.filename}`, 'success');
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(error.message || error, 'error');
        }
    });
    const extractData = (row) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Loading...',
            });
            const file = yield downloadFile({
                file_preview_type: row.override_file_path ? 'override' : 'original',
                endpoint_str_id: row.str_id,
                endpoint: 'documents',
            });
            if (!file) {
                setLoadingConfig({
                    loading: false,
                    message: '',
                });
                throw new Error('Failed to download file');
            }
            setRowData(Object.assign({}, row));
            setUploadedFile(file);
            setLoadingConfig({
                loading: false,
                message: '',
            });
            if (PDF_HTML_IMG_TYPES.includes(file.type)) {
                setShowExtract(true);
            }
            else {
                setOpen(true);
            }
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            const tip = error.message || error;
            showSnackbar(tip, 'error');
        }
    });
    const fileActionFormatter = (s, row) => row.override_file_path || row.file_path ? (_jsx(_Fragment, { children: _jsxs(Box, { className: "flex", children: [_jsx(Tooltip, { title: row.extractions.length ||
                        row.filename.includes('.xls') ||
                        row.filename.includes('.csv')
                        ? 'Process'
                        : 'Need to get the extracted data first', placement: "top", arrow: true, children: _jsx(Button, { startIcon: _jsx(Start, {}), variant: row.status === 'new' ? 'contained' : 'outlined', onClick: () => extractData(row), children: "Process" }) }), row.status === 'new' && (_jsx(IconButton, { className: "ml-1", onClick: () => deleteData(row), children: _jsx(DeleteOutline, {}) }))] }) })) : ('--');
    const overrideFilePathFormatter = (s, row) => s ? (_jsx(Tooltip, { title: s, arrow: true, enterNextDelay: 1000, children: _jsx(Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setPreviewPath(row.str_id, DocumentPreviewKeys.OVERRIDE);
                setShowPreview(true);
            }), children: _jsx("span", { style: {
                    maxWidth: 240,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }, children: s }) }) })) : (_jsx(Box, { sx: { ml: 4 }, children: _jsx(Tooltip, { title: "Add override file", placement: "right", arrow: true, children: _jsx(IconButton, { onClick: () => {
                    setRowData(row);
                    setShowUploadModal(true);
                }, children: _jsx(Add, { sx: { height: 18 } }) }) }) }));
    const filtersData = {
        type: Object.entries(DocumentTypeLabels).map(([id, name]) => ({
            id,
            name,
        })),
    };
    const dataDesc = {
        label: 'Documents',
        table: 'admin/documents',
        filters: {
            type: {
                label: 'Type',
                useEnhancedSelect: true,
            },
        },
        fields: {
            account: {
                label: 'Account',
                enabled: true,
                formatter: Formatter.getLinkChipFormatter('name', 'str_id', '/admin/accounts?id='),
            },
            filename: {
                label: 'File',
                enabled: true,
                copyable: true,
                formatter: filePathFormatter,
            },
            override_filename: {
                label: 'Override file',
                enabled: true,
                copyable: true,
                formatter: overrideFilePathFormatter,
                access: 'admin',
            },
            type: {
                label: 'Type',
                enabled: true,
                formatter: (v) => DocumentTypeLabels[v],
            },
            companies: {
                label: 'Company',
                enabled: true,
                formatter: Formatter.getLinkChipFormatter('company_name', 'str_id', '/admin/companies?id='),
            },
            statement_data: {
                label: 'Records',
                disableSort: true,
                enabled: true,
                formatter: (val, row) => {
                    var _a;
                    const groupedCountInfoStrList = [];
                    const groupedCommissionInfoStrList = [];
                    if (val.groupedCountInfo) {
                        Object.entries(val.groupedCountInfo).forEach(([key, value]) => {
                            if (key !== 'NO_STATUS') {
                                groupedCountInfoStrList.push(`${key}: ${value}`);
                            }
                        });
                    }
                    if (val.groupedCommissionInfo) {
                        Object.entries(val.groupedCommissionInfo).forEach(([key, value]) => {
                            if (key !== 'NO_STATUS') {
                                groupedCommissionInfoStrList.push(`${key}: ${Formatter.currency(value)}`);
                            }
                        });
                    }
                    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsxs(Box, { sx: { flex: 1 }, children: [!!val.total_count && (_jsxs(Box, { sx: {
                                                whiteSpace: 'nowrap',
                                            }, children: [_jsx("span", { children: +val.total_count }), groupedCountInfoStrList.length > 0 && (_jsxs("span", { style: {
                                                        color: '#444',
                                                        fontSize: 13,
                                                    }, children: ["(", groupedCountInfoStrList.toString(), ")"] }))] })), !!(val === null || val === void 0 ? void 0 : val.total_commission) && (_jsxs(Box, { sx: {
                                                whiteSpace: 'nowrap',
                                            }, children: [_jsx("span", { children: Formatter.currency(val.total_commission) }), groupedCommissionInfoStrList.length > 0 && (_jsxs("span", { style: {
                                                        color: '#444',
                                                        fontSize: 13,
                                                    }, children: ["(", groupedCommissionInfoStrList.toString(), ")"] }))] })), !(val === null || val === void 0 ? void 0 : val.total_commission) && !val.total_count && '0'] }), _jsx(Box, { children: (val.total_commission > 0 || val.total_count > 0) && (_jsxs(_Fragment, { children: [_jsx(IconButton, { component: Link, to: `/${row.type === 'statement' ? 'commissions' : 'policies'}?q=${row.str_id}`, target: "_blank", sx: {
                                                    opacity: 0.5,
                                                    '&:hover': { opacity: 1 },
                                                    color: '#2196f3',
                                                }, children: _jsx(LaunchOutlined, {}) }), isRiskTag && (_jsxs("span", { children: [((_a = row === null || row === void 0 ? void 0 : row._count) === null || _a === void 0 ? void 0 : _a.statement_data) > 0 && (_jsx(Tooltip, { title: `${row._count.statement_data} statement entries will be synced to BenefitPoint`, placement: "top", children: _jsx(IconButton, { onClick: () => {
                                                                var _a;
                                                                return setSync({
                                                                    documentId: row.str_id,
                                                                    show: true,
                                                                    count: ((_a = row === null || row === void 0 ? void 0 : row._count) === null || _a === void 0 ? void 0 : _a.statement_data) || 0,
                                                                });
                                                            }, size: "small", sx: {
                                                                opacity: 0.5,
                                                                '&:hover': { opacity: 1 },
                                                                color: '#2196f3',
                                                            }, children: _jsx(SyncOutlined, {}) }) })), _jsx(IconButton
                                                    // OnClick={() => exportData(row)}
                                                    , { 
                                                        // OnClick={() => exportData(row)}
                                                        size: "small", sx: {
                                                            opacity: 0.5,
                                                            '&:hover': { opacity: 1 },
                                                            color: '#2196f3',
                                                        }, children: _jsx(DownloadOutlined, {}) })] }))] })) })] }) }));
                },
            },
            bank_total_amount: {
                label: 'Commission totals',
                enabled: true,
                disableSort: true,
                formatter: (v, row) => {
                    var _a, _b;
                    const statementData = row.statement_data;
                    const bankTotalAmount = numberOrDefault(row.bank_total_amount, null, {
                        toFixed: 2,
                    });
                    const totalCommissionAmount = numberOrDefault(statementData.total_commission, null, { toFixed: 2 });
                    const statementTotalAmount = numberOrDefault((_b = (_a = row.imports) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.statement_total_amount, null, { toFixed: 2 });
                    if (row.status === 'new') {
                        return `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`;
                    }
                    const bankAndTotalCmsIsEqual = isEqual(bankTotalAmount, totalCommissionAmount);
                    const statementAndCmsTotalIsEqual = isEqual(totalCommissionAmount, statementTotalAmount);
                    let matchesNode = _jsx(_Fragment, {});
                    if (bankAndTotalCmsIsEqual &&
                        statementAndCmsTotalIsEqual &&
                        bankTotalAmount !== null) {
                        const tip = 'Bank total, statement total, and commission records all match';
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", arrow: true, children: _jsx("span", { children: `✅ ${Formatter.currency(bankTotalAmount)}` }) }));
                    }
                    else if (bankAndTotalCmsIsEqual && totalCommissionAmount) {
                        const tip = (_jsxs("span", { children: ["Bank total and commissions match", _jsx("br", {}), "(Statement total not available)"] }));
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", arrow: true, children: _jsx("span", { children: `✅ ${Formatter.currency(bankTotalAmount)}` }) }));
                    }
                    else if (statementAndCmsTotalIsEqual && totalCommissionAmount) {
                        const tip = 'Statement total and commission records match (Bank total not available)';
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", arrow: true, children: _jsx("span", { children: `✅ ${Formatter.currency(statementTotalAmount)}` }) }));
                    }
                    else if (!bankTotalAmount &&
                        !statementTotalAmount &&
                        !totalCommissionAmount) {
                        matchesNode = _jsx("span", { children: "0" });
                    }
                    else if (totalCommissionAmount &&
                        !statementTotalAmount &&
                        !bankTotalAmount) {
                        matchesNode = (_jsx(Tooltip, { title: _jsxs("span", { children: ["Validation not available.", _jsx("br", {}), "Bank total and/or statement total required."] }), placement: "right", arrow: true, children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { sx: { fontSize: 12 }, children: "\u2139\uFE0F" }), _jsx(Box, { sx: {
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }, children: _jsx("span", { children: `Commissions: ${Formatter.currency(totalCommissionAmount)}` }) })] }) }));
                    }
                    else {
                        matchesNode = (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { sx: { fontSize: 12 }, children: "\u274C" }), _jsxs(Box, { sx: {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }, children: [_jsx("span", { children: `Commissions: ${Formatter.currency(totalCommissionAmount)}` }), _jsx("span", { children: `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}` }), _jsx("span", { children: `Statement total: ${Formatter.currency(statementTotalAmount) || 'n/a'}` })] })] }));
                    }
                    return _jsx(Box, { sx: { whiteSpace: 'nowrap' }, children: matchesNode });
                },
            },
            created_by: {
                label: 'Imported total',
                enabled: true,
                disableSort: true,
                formatter: (v, row) => {
                    const list = row.imports;
                    if (list && list.length) {
                        const target = list[0];
                        return Formatter.currency(target === null || target === void 0 ? void 0 : target.summed_total_amount);
                    }
                },
                access: 'admin',
            },
            status: {
                label: 'Status',
                enabled: true,
                formatter: (val) => Formatter.statusChip(val, {
                    mapping: { new: 'yellow', processed: 'green' },
                }),
            },
            method: {
                label: 'Method',
                enabled: true,
                access: 'admin',
                formatter: (val, row) => val ? (_jsx(Chip, { label: val, component: Link, to: `/documents/profiles?id=${row.profile_str_id}` })) : null,
            },
            notes: {
                label: 'Notes',
                enabled: true,
            },
            imported_at: {
                label: 'Imported at',
                enabled: true,
                access: 'admin',
                formatter: (s, row) => {
                    if (!s) {
                        return '';
                    }
                    const uploadToImportTime = dayjs(s).diff(dayjs(row.created_at), 'milliseconds');
                    const uploadedInRes = `${CommonFormatter.duration(Math.abs(uploadToImportTime), { truncate: 'seconds' })}`;
                    return `${Formatter.date(s, {
                        format: 'MM/DD/YYYY hh:mmA',
                    })} (${uploadedInRes})`;
                },
            },
            created_at: {
                label: 'Uploaded at',
                enabled: true,
                formatter: (s) => {
                    return Formatter.date(s, {
                        format: 'MM/DD/YYYY hh:mmA',
                    });
                },
                readOnly: true,
            },
            id: {
                label: 'Actions',
                enabled: true,
                disableSort: true,
                formatter: fileActionFormatter,
                access: ['admin', 'demo'],
            },
        },
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            new: {
                id: 'new',
                label: 'New',
                query: {
                    status: 'new',
                },
            },
            processed: {
                id: 'processed',
                label: 'Processed',
                query: {
                    status: 'processed',
                },
            },
        },
    };
    const onConfirmMethod = (method) => {
        setRowData((prev) => (Object.assign(Object.assign({}, prev), { method })));
        setShowExtract(false);
        setOpen(true);
    };
    const extraActions = [
        {
            type: 'dateRange',
            label: 'Uploaded at',
            value: {
                startDate: null,
                endDate: null,
            },
        },
    ];
    const syncToBenefit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Syncing...',
            });
            const ret = yield syncStatement.mutateAsync(data);
            if (ret.success === false || ret.message) {
                const tip = _jsx(Alert, { severity: "error", children: ret.message });
                showSnackbar(tip);
            }
            else {
                showSnackbar(_jsxs(Alert, { severity: "success", children: ["Sync succeessfully, synced statmentID: ", ret.statementId] }));
            }
        }
        catch (error) {
            const tip = _jsx(Alert, { severity: "error", children: error.message || error });
            showSnackbar(tip);
        }
        finally {
            setLoadingConfig({
                loading: false,
                message: '',
            });
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataDesc, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false, 
                // @ts-ignore
                extraActions: extraActions, variant: variant, filters: filtersData }), open && (_jsx(UpdateProcessData, { open: open, rowData: rowData, setRowData: setRowData, handleClose: (arg) => {
                    queryClient.invalidateQueries();
                    setOpen(arg);
                } })), showUploadModal && (_jsx(UploadOverrideFile, { open: showUploadModal, setOpen: setShowUploadModal, uploadedRow: rowData })), showExtract && (_jsx(ExtractMethod, { showExtract: showExtract, onClose: () => setShowExtract(false), onConfirm: onConfirmMethod, uploadedRow: rowData })), showPreview && (_jsx(FileDialogPreview, { showPreview: showPreview, setShowPreview: setShowPreview, fileId: previewId, isAdmin: true })), _jsx(BasicDialog, { open: sync.show, title: "Sync data", bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to sync ", sync.count, " statement entries to BenefitPoint?"] }), onClose: (isOk) => {
                    if (isOk) {
                        syncToBenefit({ documentId: sync.documentId });
                    }
                    setSync(Object.assign(Object.assign({}, sync), { show: false }));
                }, positiveLabel: "Sync" })] }));
};
export default DocumentsView;
