import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, InputLabel, TextField, } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
export const ExpandableTextField = ({ editableSx, sx, label, onChange, defaultValue, error, }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const isShrink = value !== '' || isFocused;
    const refInput = useRef(null);
    const refDefaultValue = useRef(defaultValue);
    const onInput = (e) => {
        if (refInput.current) {
            const text = refInput.current.innerText || '';
            onChange === null || onChange === void 0 ? void 0 : onChange(text);
            setValue(text);
        }
    };
    const onBlurStyle = {
        height: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '& div': {
            display: 'inline-block',
        },
    };
    useEffect(() => {
        if (refInput.current)
            refInput.current.innerText = refDefaultValue.current || '';
    }, []);
    return (_jsxs(FormControl, { sx: Object.assign({ minWidth: 160, marginRight: 1 }, sx), children: [_jsx(InputLabel, { sx: {
                    paddingLeft: 1,
                    paddingRight: 1,
                    background: 'white',
                    marginTop: isShrink ? 0 : '-3px',
                }, shrink: isShrink, children: label }), _jsx(TextField, { error: error, sx: {
                    '& .MuiInputBase-root': {
                        minHeight: '40px',
                        paddingRight: 0,
                        maxWidth: '100%',
                    },
                    '& .MuiInputBase-input': {
                        opacity: 0,
                        width: 0,
                        paddingRight: 0,
                        height: '100%',
                        py: 0,
                    },
                }, InputProps: {
                    startAdornment: (_jsx(Box, { onPaste: (e) => {
                            e.preventDefault();
                            const text = e.clipboardData.getData('text/plain');
                            if (refInput.current)
                                refInput.current.innerText = text;
                            onChange === null || onChange === void 0 ? void 0 : onChange(text);
                            setValue(text);
                        }, ref: refInput, onFocus: () => setIsFocused(true), onBlur: () => setIsFocused(false), onInput: onInput, contentEditable: true, sx: Object.assign(Object.assign({ width: '100%', zIndex: 1, outline: 'none', padding: '8px 14px', paddingLeft: 0 }, (isFocused ? {} : onBlurStyle)), editableSx) })),
                } })] }));
};
